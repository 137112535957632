import React from 'react';
import { Spacing, Link } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import BadgeNew from '../../ui/atoms/BadgeNew';
import CheckList from '../../ui/atoms/CheckList';
import KueskiIcons from '../../ui/templates/KueskipayIcons';
import PaymentImage from '../../ui/atoms/PaymentImage';

const KueskipayForm = () => {
  const { t } = useTranslation('payment');
  const labels = [
    t('kueskipay_instructions.first'),
    t('kueskipay_instructions.second'),
    t('kueskipay_instructions.third'),
    t('kueskipay_instructions.fourth'),
  ];

  const disclaimerUrls = [
    {
      urlText: t('link_text.kueski_terms'),
      href: t('link_url.kueski_terms'),
    },
    {
      urlText: t('link_text.kueski_how_to_pay'),
      href: t('link_url.kueski_how_to_pay'),
    },
  ];

  return (
    <div className="checkout-section">
      <Spacing vertical>
        <Spacing alignItems="flex-end" size="S">
          <PaymentImage type="kueskipay-logo" />
          <BadgeNew />
        </Spacing>
        <KueskiIcons />
        <CheckList
          title={t('label.kueskipay')}
          labels={labels}
          disclaimer={t('text.kueskipay_disclaimer')}
        />
        <Spacing>
          {disclaimerUrls.map((data) => (
            <Link href={data.href} text={data.urlText} type="accent" newTab size="XS" />
          ))}
        </Spacing>
      </Spacing>
    </div>
  );
};

export default KueskipayForm;
