/**
 * CieloEngine implementation.
 */
const CieloEngine = () => {
  let tokenizationEndpoint = '';
  let threeDSTokenEndpoint = '';

  /**
   * Initializes the CieloEngine with the provided config.
   * @param {object} config - Configuration object
   * @param {string} config.tokenizeEndpoint - The endpoint for tokenization
   * @param {string} config.threeDSTokenEndpoint - The endpoint for 3DS token
   */
  const init = ({ tokenizeEndpoint, threeDSTokenEndpoint: threeDSTokenEndpointFromConfig }) => {
    tokenizationEndpoint = tokenizeEndpoint;
    threeDSTokenEndpoint = threeDSTokenEndpointFromConfig;
  };

  /**
   * Gets the 3DS authentication token from Cielo.
   * @returns {Promise<string>} The 3DS authentication token
   * @throws {Error} If the request fails
   */
  const get3DSToken = async () => {
    try {
      const response = await fetch(threeDSTokenEndpoint);

      if (!response.ok) {
        throw new Error('Failed to get 3DS token');
      }

      const data = await response.json();
      return data.access_token;
    } catch (error) {
      console.error('Error getting 3DS token:', error);
      throw error;
    }
  };

  /**
   * Tokenizes a card.
   * @param {Object} param - The card details.
   * @param {string} param.holderName - The card holder's name.
   * @param {string} param.cardNumber - The card number.
   * @param {string} param.expirationMonth - The card's expiration month.
   * @param {string} param.expirationYear - The card's expiration year.
   * @param {string} param.cardBrand - The card's brand.
   * @param {string} param.cvv - The card's CVV.
   * @returns {Promise<string>} The Cielo token.
   */
  const tokenizeCard = async ({
    holderName,
    cardNumber,
    expirationMonth,
    expirationYear,
    cardBrand,
    cvv,
  }) => {
    const response = await fetch(tokenizationEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        card_holder_name: holderName,
        card_number: cardNumber,
        card_exp_month: expirationMonth,
        card_exp_year: expirationYear,
        card_brand: cardBrand,
        payment_engine: 'cielo',
        card_cvv: cvv,
      }),
    });
    const data = await response.json();
    return data.cielo_token;
  };

  return {
    init,
    tokenizeCard,
    get3DSToken,
  };
};

const cieloEngine = CieloEngine();

export default cieloEngine;
