import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import CheckoutWalletCheckbox from './CheckoutWalletCheckbox';
import CheckoutWalletInput from './CheckoutWalletInput';

/**
 * Function that returns the context for the wallet checkbox
 * @param {Boolean} loadingWallet - Indicates if the wallet is being updated.
 * @param {Boolean} usingWallet - Indicates if the wallet is being used.
 * @returns {loading | using} The context for the wallet checkbox.
 */
function checkboxI18nContext(loadingWallet, usingWallet) {
  if (loadingWallet) return 'loading';
  if (usingWallet) return 'using';

  return '';
}

/**
 * Component that renders the wallet section in the checkout page.
 * @param {Object} props - Object props
 * @param {string} props.selectedPaymentOption - The selected payment option.
 * @param {boolean} props.usingWallet - Indicates if the wallet is being used.
 * @param {boolean} props.updatingWallet - Indicates if the wallet is being updated.
 * @param {string} props.purchaseToken - The purchase token.
 * @param {number} props.maxUsableBalance - The maximum usable balance.
 * @param {function} props.setUsingWallet - Function to set the wallet usage.
 * @param {function} props.updatePurchaseUser - Function to update the purchase user.
 * @param {number} props.walletBalanceUsed - The wallet balance used.
 * @param {boolean} props.isExchange - Indicates if the purchase is an exchange.
 * @param {number} props.total - The total amount of the purchase.
 * @param {Object} props.user - The user object.
 * @param {string} props.chosenSpPassengerId - The chosen sp passenger id.
 * @param {string} props.walletType - The wallet type.
 * @returns {JSX.Element} A React component that renders the wallet section in the checkout page.
 */
const CheckoutWallet = ({
  selectedPaymentOption,
  purchaseToken,
  setUsingWallet,
  updatingWallet,
  usingWallet,
  walletBalanceUsed,
  maxUsableBalance,
  chosenSpPassengerId,
  total,
  isExchange,
  user,
  walletType,
  updatePurchaseUser,
}) => {
  const { t } = useTranslation('loyalty');
  const environment = useWhitelabelEnvs();

  const validWalletType =
    walletType !== 'doters' && walletType !== 'travelpass' && walletType !== 'costapass';

  const prevUserRef = useRef();
  useEffect(() => {
    const prevUser = prevUserRef.current;
    if (validWalletType && !prevUser && user) {
      updatePurchaseUser(purchaseToken);
    }
    prevUserRef.current = user;
  }, [user, validWalletType, updatePurchaseUser, purchaseToken]);

  const loadingWallet = updatingWallet;

  if (!user) return null;
  if (walletType === 'doters' || walletType === 'travelpass' || walletType === 'costapass')
    return null;
  if (selectedPaymentOption === 'oxxo') return null;

  const { availableKms: walletBalance } = user;

  const usableBalance = walletBalance >= maxUsableBalance ? maxUsableBalance : walletBalance;

  let wallet;
  let canUsePoints = total !== 0;

  if (!chosenSpPassengerId) {
    wallet = <Text>{t('user_not_a_passenger', { context: environment.brand })}</Text>;
    canUsePoints = false;
  } else if (usableBalance <= 0) {
    wallet = <Text>{t('no_wallet_funds_message')}</Text>;
    canUsePoints = false;
  } else if (usingWallet) {
    wallet = (
      <CheckoutWalletCheckbox
        checkboxContext={checkboxI18nContext}
        setUsingWallet={setUsingWallet}
        loadingWallet={loadingWallet}
        usingWallet={usingWallet}
        usableBalance={usableBalance}
        walletBalanceUsed={walletBalanceUsed}
        purchaseToken={purchaseToken}
      />
    );
  } else {
    wallet = (
      <CheckoutWalletInput
        checkboxContext={checkboxI18nContext}
        setUsingWallet={setUsingWallet}
        loadingWallet={loadingWallet}
        usableBalance={usableBalance}
        purchaseToken={purchaseToken}
      />
    );
  }

  if (isExchange && !canUsePoints) return null;

  return (
    <div className="checkout-section">
      <Spacing vertical size="S">
        <Text weight="bold">{t('wallet_name')}</Text>
        {wallet}
      </Spacing>
    </div>
  );
};

CheckoutWallet.propTypes = {
  selectedPaymentOption: PropTypes.string.isRequired,
  usingWallet: PropTypes.bool.isRequired,
  updatingWallet: PropTypes.bool.isRequired,
  purchaseToken: PropTypes.string.isRequired,
  maxUsableBalance: PropTypes.number.isRequired,
  setUsingWallet: PropTypes.func.isRequired,
  updatePurchaseUser: PropTypes.func.isRequired,
  walletBalanceUsed: PropTypes.number.isRequired,
  isExchange: PropTypes.bool,
  total: PropTypes.number,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    spCard: PropTypes.string.isRequired,
    availableKms: PropTypes.number.isRequired,
  }),
  chosenSpPassengerId: PropTypes.string,
  walletType: PropTypes.string.isRequired,
};

CheckoutWallet.defaultProps = {};

export default CheckoutWallet;
