import defaultEnvs from 'config/defaultEnvs';
import defaultFeatures from 'config/defaultFeatures';

const whitelabelContextEndpoint = process.env.RESERVAMOS_PUBLIC_WL_CONTEXT_ENDPOINT;

/**
 * Gets the remote configuration from the white label context endpoint
 * @returns {Promise<{environment: object, features: object, copies: object}>}
 * @throws {Error} If the request fails
 */
const getRemoteConfig = async () => {
  const response = await fetch(whitelabelContextEndpoint);

  if (!response.ok) {
    throw new Error(
      `Error fetching remote configuration: ${response.status} ${response.statusText}`,
    );
  }

  const whitelabelContext = await response.json();
  const {
    environment_variables: environment,
    feature_flags: features,
    copies,
  } = whitelabelContext.config;

  return {
    environment,
    features,
    copies,
  };
};

async function fetchWhiteLabelConfig() {
  const remoteConfig = await getRemoteConfig();

  return {
    env: {
      ...defaultEnvs,
      ...remoteConfig.environment,
    },
    features: {
      ...defaultFeatures,
      ...remoteConfig.features,
    },
    copies: {
      ...remoteConfig.copies,
    },
  };
}

export default fetchWhiteLabelConfig;
