import React from 'react';
import PropTypes from 'prop-types';
import { getValidationFeedbackClass } from 'utils/formValidations';
import inputStyles from './inputStyles';

/**
 * CieloTDCFormSelect component renders a select field for the Cielo TDC form.
 * It includes a label and validation feedback.
 *
 * @param {Object} props - The props for the CieloTDCFormSelect component.
 * @param {string} props.id - The ID of the select field.
 * @param {string} props.name - The name of the select field.
 * @param {string} props.className - The class name for the select field.
 * @param {string} props.value - The value of the select field.
 * @param {boolean} props.disabled - Whether the select field is disabled.
 * @param {function} props.onBlur - The function to call when the select field loses focus.
 * @param {function} props.onChange - The function to call when the select field changes.
 * @param {array} props.options - The options for the select field.
 * @param {boolean} props.isValid - Whether the select field is valid.
 * @param {boolean} props.hasError - Whether the select field has an error.
 * @returns {React.Component} The CieloTDCFormSelect component.
 */
const CieloTDCFormSelect = ({
  id,
  name,
  className = '',
  value = '',
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  options = [],
  isValid = false,
  hasError = false,
}) => {
  return (
    <div className="form-item-select w-full">
      <select
        id={id}
        name={name}
        className={`${className} ${inputStyles} ${getValidationFeedbackClass(isValid, hasError)}`}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.display}
          </option>
        ))}
      </select>
    </div>
  );
};

CieloTDCFormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  isValid: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default CieloTDCFormSelect;
