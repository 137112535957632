import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CurrentLang } from 'utils/lang';
import { When } from 'react-if';
import { Text, FlatButton, Link, Modal, Spacing, MessageBox, Icon } from '@reservamos/elements';
import 'styles/components/overlay/PassengerPolicies';
import { useSelector } from 'react-redux';

/**
 * PassengerPolicies component.
 * PassengerPolicies component.
 * @param {object} props - location, isCheckbox
 * @param {string} props.location - Location identifier to determine display context ('checkout' or other)
 * @param {boolean} props.isCheckbox - Whether to render in checkbox context with smaller text
 */
const PassengerPolicies = ({ location, isCheckbox }) => {
  const { features, copies } = useSelector((state) => state.whitelabelConfig);
  const { purchase } = useSelector((state) => state);
  const { isDotersUser } = purchase.toJS();
  const { t } = useTranslation('seats');
  const lang = CurrentLang();

  const {
    underagePolicies = [],
    termsAndConditionsUrl,
    noticeOfPrivacy,
    luggagePolicies,
    petsPolicies,
    travelWithUnderagePolicies,
    travelPolicies,
  } = copies.policies[lang] || {};

  const messageContext = termsAndConditionsUrl ? null : 'female';

  const textSize = isCheckbox ? 'S' : 'XS';

  if (!features.PASSENGERS_TERMS_AND_CONDITIONS_ENABLED) return null;

  /**
   * Renders a container for policy-related content with optional alert styling
   * This optional alert is only shown in the checkout page and tenants with the feature SHOW_POLICIES_CONTAINER_ALERT enabled
   * @param {object} props
   * @param {React.ReactNode} props.children - Child elements to render within the container
   */
  // eslint-disable-next-line react/prop-types
  const PoliciesContainer = ({ children }) => {
    const alertContent = (
      <MessageBox borderColor="warning" borderRadius="L" roundCorners widthFull>
        <Spacing size="XS" alignItems="center">
          <Icon Name="emoji Raised Hand" size="S" type="emojiRaisedHand" />
          <Text size="S" weight="semibold">
            {location === 'checkout'
              ? t('passenger_policy.finalize_purchase')
              : t('passenger_policy.by_continuing')}
          </Text>
        </Spacing>
        {children}
      </MessageBox>
    );

    const defaultContent = (
      <Text size={textSize}>
        {t('passenger_policy.message', { context: messageContext })}
        {children}
      </Text>
    );

    return <>{features.SHOW_POLICIES_CONTAINER_ALERT ? alertContent : defaultContent}</>;
  };

  /**
   * Renders a Link component if the condition is met
   * @param {boolean} condition - Determines if the link should be rendered
   * @param {string} text - Text to display in the link
   * @param {string} href - URL for the link
   * @returns {React.ReactElement|null} Link component or null
   */
  const renderLink = (condition, text, href) =>
    condition ? (
      <Link text={text} href={href} size={textSize} type="accent" weight="regular" newTab />
    ) : null;

  return (
    <>
      <div className={isCheckbox ? '' : 'passenger-policies-container'}>
        <PoliciesContainer>
          <When condition={Boolean(features.SHOW_POLICIES_CONTAINER_ALERT)}>
            {features.DOTERS_NO_CANCELLATION_NOTICE && isDotersUser && (
              <Spacing size="XS">
                <Text size="S" color="grayMedium">
                  • {`${t('seats:passenger_policy.doters_no_cancelable')} `}
                  <Text elementType="span" size="S">
                    {t('seats:passenger_policy.when_using_doters').toLowerCase()}
                  </Text>
                </Text>
              </Spacing>
            )}
            <Text size="S" color="grayMedium">
              &#8226; {t('passenger_policy.you_are_acepting')}{' '}
              <When condition={Boolean(termsAndConditionsUrl)}>
                <Link
                  text={t('passenger_policy.terms_and_conditions')}
                  href={termsAndConditionsUrl}
                  size="S"
                  type="accent"
                  weight="regular"
                  newTab
                />
                . {t('passenger_policy.you_can_check_our')}{' '}
                <Link
                  text={t('passenger_policy.here')}
                  href={noticeOfPrivacy?.url}
                  size="S"
                  type="accent"
                  weight="regular"
                  newTab
                />
              </When>
            </Text>
          </When>

          <When condition={Boolean(!features.SHOW_POLICIES_CONTAINER_ALERT)}>
            {renderLink(
              Boolean(termsAndConditionsUrl),
              t('passenger_policy.terms_and_conditions'),
              termsAndConditionsUrl,
            )}
            <When condition={Boolean(luggagePolicies)}>
              {termsAndConditionsUrl && t('passenger_policy.comma_separator')}
              {renderLink(Boolean(luggagePolicies), luggagePolicies?.label, luggagePolicies?.url)}
            </When>
            <When condition={Boolean(petsPolicies)}>
              {luggagePolicies && t('passenger_policy.comma_separator')}
              {renderLink(Boolean(petsPolicies), petsPolicies?.label, petsPolicies?.url)}
            </When>
            <When condition={Boolean(travelWithUnderagePolicies)}>
              {petsPolicies && t('passenger_policy.comma_separator')}
              {renderLink(
                Boolean(travelWithUnderagePolicies),
                travelWithUnderagePolicies?.label,
                travelWithUnderagePolicies?.url,
              )}
            </When>
            {underagePolicies.length > 0
              ? t('passenger_policy.comma_separator')
              : t('passenger_policy.and_separator')}
            {renderLink(
              Boolean(noticeOfPrivacy),
              noticeOfPrivacy?.label || t('passenger_policy.privacy_notice'),
              noticeOfPrivacy?.url,
            )}

            <When condition={Boolean(travelPolicies)}>
              {t('policies_separator')}
              {renderLink(
                Boolean(travelPolicies),
                travelPolicies?.label || t('passenger_policy.travel_policies'),
                travelPolicies?.url,
              )}
            </When>
            {underagePolicies.length > 0 && (
              <>
                {t('policies_separator')}
                <Modal
                  headerColor="primary"
                  title={t('general:policies_title')}
                  modalTrigger={
                    <FlatButton size="XS" weight="regular">
                      {t('see_policies')}
                    </FlatButton>
                  }
                >
                  <Spacing vertical>
                    {Boolean(underagePolicies.length) &&
                      underagePolicies.map((item) => (
                        <Text key={item} size="S">
                          <b>·</b>
                          {` ${item}`}
                        </Text>
                      ))}
                  </Spacing>
                </Modal>
              </>
            )}
            {features.DOTERS_ENABLED ? `; ${t('passenger_policy.doters')}` : '.'}
          </When>
          {features.SHOW_PASSENGER_AUTOFILL_REMINDER && t('passenger_policy.auto_fill_reminder')}
        </PoliciesContainer>
      </div>
    </>
  );
};

PassengerPolicies.propTypes = {
  isCheckbox: PropTypes.bool,
  location: PropTypes.string,
};

PassengerPolicies.defaultProps = {
  isCheckbox: false,
};

export default PassengerPolicies;
