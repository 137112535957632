// @ts-check

/**
 * @typedef {Object} YunoCheckoutSession
 * @property {string} checkoutSession - The Yuno checkout session ID
 * @property {string} merchantOrderId - The merchant order ID
 */

/**
 * @typedef {Object} YunoSessionData
 * @property {Object} availableMethods - Available payment methods
 */

/**
 * Yuno payment engine implementation.
 * @function Yuno
 */
const Yuno = () => {
  let baseUrl = '';

  /**
   * Initializes the Yuno engine with configuration
   * @param {string} purchaseUrl - Base URL for the purchase API
   */
  const initialize = (purchaseUrl) => {
    baseUrl = purchaseUrl;
  };

  /**
   * Creates a checkout session with Yuno
   * @async
   * @param {Object} params - Parameters for creating checkout session
   * @param {string} params.purchaseToken - The purchase token
   * @param {number} params.amount - The payment amount
   * @returns {Promise<YunoCheckoutSession>} The created checkout session
   * @throws {Error} If session creation fails
   */
  const createCheckoutSession = async ({ purchaseToken, amount }) => {
    try {
      const response = await fetch(`${baseUrl}/v2/yuno/checkout_session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          purchase_token: purchaseToken,
          amount,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const { checkout_session: checkoutSession, merchant_order_id: merchantOrderId } = data || {};

      if (!checkoutSession || !merchantOrderId) {
        throw new Error(
          'Invalid response: Missing required fields "checkout_session" or "merchant_order_id".',
        );
      }

      return {
        checkoutSession,
        merchantOrderId,
      };
    } catch (error) {
      throw new Error(`Failed to create Yuno checkout session: ${error.message}`);
    }
  };

  /**
   * Gets session data for a Yuno checkout
   * @async
   * @param {Object} params - Parameters for getting session data
   * @param {string} params.checkoutSession - The checkout session ID
   * @param {string} params.purchaseToken - The purchase token
   * @returns {Promise<YunoSessionData>} The session data
   * @throws {Error} If data retrieval fails
   */
  const getSessionData = async ({ checkoutSession, purchaseToken }) => {
    try {
      const response = await fetch(
        `${baseUrl}/v2/yuno/checkout_session/${checkoutSession}?purchase_token=${purchaseToken}`,
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const availableMethods = await response.json();

      if (!availableMethods || typeof availableMethods !== 'object') {
        throw new Error(
          'Invalid response: Expected availableMethods data structure is missing or malformed.',
        );
      }

      return { availableMethods };
    } catch (error) {
      throw new Error(`Failed to get Yuno session data: ${error.message}`);
    }
  };

  return {
    initialize,
    createCheckoutSession,
    getSessionData,
  };
};

const yuno = Yuno();

export default yuno;
