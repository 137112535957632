import React from 'react';
import PropTypes from 'prop-types';
import { FieldLabel } from '@reservamos/elements';
import { getValidationFeedbackClass } from 'utils/formValidations';
import inputStyles from './inputStyles';

/**
 * CieloTDCFormInput component renders an input field for the Cielo TDC form.
 * It includes a label and validation feedback.
 *
 * @param {Object} props - The props for the CieloTDCFormInput component.
 * @param {string} props.id - The ID of the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.className - The class name for the input field.
 * @param {string} props.value - The value of the input field.
 * @param {boolean} props.disabled - Whether the input field is disabled.
 * @param {function} props.onBlur - The function to call when the input field loses focus.
 * @param {function} props.onChange - The function to call when the input field changes.
 * @param {string} props.label - The label text for the input field.
 * @param {boolean} props.isValid - Whether the input field is valid.
 * @param {boolean} props.hasError - Whether the input field has an error.
 * @returns {React.Component} The CieloTDCFormInput component.
 */
const CieloTDCFormInput = ({
  id,
  name,
  className = '',
  value = '',
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  label = '',
  isValid = false,
  hasError = false,
}) => {
  return (
    <div className="grid gap-1 relative w-full">
      <FieldLabel inputId={id} text={label}>
        {label}
      </FieldLabel>
      <input
        id={id}
        name={name}
        className={`${className} ${inputStyles} ${getValidationFeedbackClass(isValid, hasError)}`}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

CieloTDCFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isValid: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default CieloTDCFormInput;
