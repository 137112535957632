import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import ErrorFallback from './ErrorFallback';
import { CurrentLang } from '../utils/lang';

// Create an independent instance of i18next
const i18n = i18next.createInstance();

// Get current language, passing false to avoid store dependency
const currentLang = CurrentLang({ shouldReadDefaultLanguageFromStore: false });

i18n.use(initReactI18next).init({
  lng: currentLang,
  resources: {
    'en-US': {
      errors: {
        something_went_wrong: 'Sorry, an unexpected error occurred',
      },
      general: {
        go_back: 'Go back',
      },
    },
    'es-MX': {
      errors: {
        something_went_wrong: 'Lo sentimos, ocurrió un error inesperado',
      },
      general: {
        go_back: 'Regresar',
      },
    },
    'es-CO': {
      errors: {
        something_went_wrong: 'Lo sentimos, ocurrió un error inesperado',
      },
      general: {
        go_back: 'Regresar',
      },
    },
    'es-PE': {
      errors: {
        something_went_wrong: 'Lo sentimos, ocurrió un error inesperado',
      },
      general: {
        go_back: 'Regresar',
      },
    },
    'fr-FR': {
      errors: {
        something_went_wrong: "Désolé, une erreur inattendue s'est produite",
      },
      general: {
        go_back: 'Retour',
      },
    },
    'nl-NL': {
      errors: {
        something_went_wrong: 'Sorry, er is een onverwachte fout opgetreden',
      },
      general: {
        go_back: 'Terug',
      },
    },
    'pt-BR': {
      errors: {
        something_went_wrong: 'Desculpe, ocorreu um erro inesperado',
      },
      general: {
        go_back: 'Voltar',
      },
    },
  },
  fallbackLng: 'es-MX',
  defaultNS: 'errors',
});

/**
 * TranslatedErrorFallback component.
 * Wraps ErrorFallback with i18n support.
 * @param {object} props - resetError
 * @param {Function} props.resetError - Function to reset the error state
 */
const TranslatedErrorFallback = ({ resetError }) => (
  <I18nextProvider i18n={i18n}>
    <ErrorFallback resetError={resetError} />
  </I18nextProvider>
);

TranslatedErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default TranslatedErrorFallback;
