import { connect } from 'react-redux';
import { confirmPaypalPayment } from '@/actions/payment';
import { compose } from 'redux';
import { timeoutTick } from '../../../actions/purchase';
import withPurchaseTimer from '../../../hocs/withPurchaseTimer';
import PaypalPaymentInfo from './PaypalPaymentInfo';

const mapStateToProps = ({ purchase, payment }) => ({
  amount: payment.get('amount'),
  currency: payment.get('paypalCurrency'),
  departs: purchase.getIn(['departs', 'departure']),
  isRoundTrip: purchase.get('roundTrip'),
  from: purchase.getIn(['departs', 'origin', 'cityName']),
  paymentId: payment.get('id'),
  to: purchase.getIn(['departs', 'destination', 'cityName']),
  paymentType: payment.get('paymentType') || 'credit_card',
  token: purchase.get('token'),
  isOpenTicket: purchase.get('openTicket'),
});

const mapDispatchToProps = {
  confirmPaypalPayment,
  timeoutTick,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPurchaseTimer,
)(PaypalPaymentInfo);
