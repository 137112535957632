import React from 'react';
import useCieloTDCContext from './useCieloTDCContext';

/**
 * Higher-order component that enhances a component with Cielo TDC context.
 *
 * @param {React.Component} Component - The component to be enhanced.
 * @returns {React.Component} - The enhanced component.
 */
const withCieloTDCContext = (Component) => {
  /**
   * Wrapped component that receives the Cielo TDC context as props.
   *
   * @param {Object} props - The component props.
   * @returns {React.Element} - The rendered component.
   */
  const WrappedComponent = (props) => {
    const cieloTDCContext = useCieloTDCContext();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} cieloTDCContext={cieloTDCContext} />;
  };

  return WrappedComponent;
};

export default withCieloTDCContext;
