import { useState, useEffect } from 'react';

const defaultOptions = {
  shouldLoadScript: true,
  removeOnUnmount: false,
  onUnmount: () => {},
};

/**
 * Hook to load a script and handle its loading and error states.
 *
 * @param {string} scriptSrc - The source URL of the script to load.
 * @param {Object} options - Configuration options for the script loading.
 * @param {boolean} options.shouldLoadScript - Whether to load the script.
 * @param {boolean} options.removeOnUnmount - Whether to remove the script on unmount.
 * @param {Function} options.onUnmount - Callback function to be called when the script is unmounted. Only called if removeOnUnmount is true.
 * @returns {Object} An object containing the script loading status.
 */
const useScript = (
  scriptSrc,
  { shouldLoadScript = true, removeOnUnmount = false, onUnmount = () => {} } = defaultOptions,
) => {
  const [scriptStatus, setScriptStatus] = useState({
    isLoaded: false,
    hasError: false,
  });

  const handleError = () => {
    setScriptStatus({
      isLoaded: false,
      hasError: true,
    });
  };

  const handleLoad = () => {
    setScriptStatus({
      isLoaded: true,
      hasError: false,
    });
  };

  useEffect(() => {
    let script;
    if (shouldLoadScript) {
      script = document.createElement('script');

      script.src = scriptSrc;
      script.async = true;
      script.onerror = handleError;
      script.onload = handleLoad;

      document.body.appendChild(script);
    }

    return () => {
      if (script && removeOnUnmount) {
        document.body.removeChild(script);
        onUnmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadScript, scriptSrc]);

  return scriptStatus;
};

export default useScript;
