import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Button } from '@reservamos/elements';
import { formatCurrency } from 'utils/Helpers';
import Timer from '../Timer';
import TimeoutModal from '../TimeoutModal';
import PaymentImage from '../../../ui/atoms/PaymentImage';
import 'styles/components/purchase/TicketsPanel';

const PaypalPaymentInfo = ({
  amount,
  currency,
  confirmPaypalPayment,
  departs,
  from,
  isRoundTrip,
  paymentId,
  to,
  paymentType,
  token,
  timerRemainingTime,
  isOpenTicket,
}) => {
  const { t } = useTranslation('general');
  const departsDisplay = moment(departs).format('LLL');
  const amountDisplay = formatCurrency(amount, 2, currency);

  const onConfirm = () => {
    confirmPaypalPayment(token, paymentId);
  };

  let buttonText;
  if (paymentType === 'paypal') buttonText = t('payment:button.pay_in_paypal');
  if (paymentType === 'third_party') buttonText = t('payment:button.pay_in_coppel_pay');

  return (
    <div>
      <div className="tickets-panel">
        <div className="tickets-panel-content">
          <Spacing vertical>
            {paymentType === 'paypal' && <PaymentImage type="paypal-logo" />}
            {paymentType === 'third_party' && <PaymentImage type="coppelpay" />}
            <Text size="S" weight="bold">
              {t('payment:label.purchase_summary')}:
            </Text>
            <Spacing vertical size="XS">
              <Text size="S" weight="bold">
                {t('payment:trip', { context: isRoundTrip ? 'round' : 'one_way' })}
              </Text>
              <Text size="XL">
                {from} - {to}
              </Text>
            </Spacing>
            {!isOpenTicket && (
              <Spacing vertical size="XS">
                <Text size="S" weight="bold">
                  {t('payment:label.departure_date')}
                </Text>
                <Text size="L">{departsDisplay}</Text>
              </Spacing>
            )}
          </Spacing>
        </div>

        <div className="tickets-panel-content right-content">
          <div className="right-content-info">
            <Spacing vertical size="S">
              <Timer variant="payment" remainingTime={timerRemainingTime} />
              <Button variant="primary" onClick={onConfirm} text={buttonText} />
            </Spacing>
          </div>
          <div className="right-content-price">
            <Spacing vertical size="S">
              <Text size="S" textAlign="center" color="grayMedium">
                {t('payment:label.pending_payment')}:
              </Text>
              <div className="right-content-price-amount">
                <Text size="XL" weight="bold" textAlign="center">
                  {amountDisplay}
                </Text>
              </div>
            </Spacing>
          </div>
        </div>
      </div>
      <TimeoutModal />
    </div>
  );
};

PaypalPaymentInfo.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string,
  confirmPaypalPayment: PropTypes.func.isRequired,
  departs: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  isRoundTrip: PropTypes.bool.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
  paymentId: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  paymentType: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  timerRemainingTime: PropTypes.number.isRequired,
};

export default PaypalPaymentInfo;
