import { useState, useEffect } from 'react';
import { negativeAsZero } from 'utils/currency';

// This value is used to avoid the slider to be set to points that left PRICE_REMAINING_TO_AVOID amount to be paid by other methods
const PRICE_REMAINING_TO_AVOID = 1;

const useDotersSlider = ({
  wallet,
  profile,
  onApplyPointsDiscount,
  onRemovePointsDiscount,
  purchaseTotal,
}) => {
  // Slider state logic ------------------
  const [disabled, setDisabled] = useState(false);
  const [sliderPoints, setSliderPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);
  const [error, setError] = useState(false);
  // To enable/disable "Apply" button
  const [canApplyPoints, setCanApplyPoints] = useState(false);
  const { maxUsableBalance, minPointsToUse, usedPoints, usedBalance } = wallet;

  // Slider props --------------------------
  const minPointsToBeEnable = 100;
  const isJustFlatFare = Boolean(minPointsToUse) && !maxUsableBalance;
  const pointsInMoney = Math.floor(sliderPoints * profile.pointsFactor * 100) / 100;

  const totalBalance = !disabled
    ? negativeAsZero(purchaseTotal + usedBalance - pointsInMoney)
    : purchaseTotal;
  const replacesRegexComas = /\B(?=(\d{3})+(?!\d))/g;
  const formattedUsableBalance = parseInt(maxPoints, 10)
    .toString()
    .replace(replacesRegexComas, ',');

  /**
   * Set the slider points to the max points available
   * If the profile has used points before, set the slider points to the points used
   */
  useEffect(() => {
    const pointsToBeSet = usedPoints || maxPoints || minPointsToUse;
    setSliderPoints(pointsToBeSet);
  }, [usedPoints, maxPoints, minPointsToUse]);

  useEffect(() => {
    if (usedBalance > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [usedBalance]);

  // To enable/disable "Apply" button
  useEffect(() => {
    if (sliderPoints > 0 || wallet.usedPoints > 0) {
      setCanApplyPoints(true);
    } else {
      setCanApplyPoints(false);
    }
  }, [sliderPoints, wallet.usedPoints]);

  // Can be calculated at LoyaltyProgramsProvider
  useEffect(() => {
    if (!profile) return;
    const { pointsFactor, availablePoints } = profile;

    const pointsAvailableInMoney = availablePoints * pointsFactor;
    const maxUsableBalanceNumber = Number.isNaN(maxUsableBalance)
      ? parseInt(maxUsableBalance, 10)
      : maxUsableBalance;

    const maxUsablePoints = () => {
      if (pointsAvailableInMoney >= maxUsableBalance) {
        let points = parseInt(maxUsableBalanceNumber / pointsFactor, 10);
        const pointsConverted = points * pointsFactor;
        if (availablePoints > points && pointsConverted < maxUsableBalance) points += 1;
        return points;
      }
      return availablePoints;
    };
    setMaxPoints(maxUsablePoints());
  }, [maxUsableBalance, profile]);

  // End Slider state logic ------------------

  // Slider handlers -------------------------

  const handleOnDelete = () => {
    if (wallet.isUpdating) return;

    onRemovePointsDiscount();
    setError(false);
  };

  const handleOnSubmitPoints = (sliderPoints) => {
    if (wallet.isUpdating) return;

    if (profile.availablePoints > 0 && sliderPoints <= maxPoints) {
      if (sliderPoints === usedPoints) {
        setDisabled(true);
      } else {
        onApplyPointsDiscount({ pointsToApply: sliderPoints, walletPointsUsed: usedPoints });
        setError(false);
      }
    }
  };

  const handleOnChange = (e) => {
    if (disabled) return;
    let { value } = e.target;
    if (Number(value) > maxPoints || (/\D/.test(value) && value !== '')) return null;

    const moneyValue = (value * profile.pointsFactor * 100) / 100;
    const remainingToPayTotal = purchaseTotal + usedBalance - moneyValue;
    /**
     * Check if price remaining to pay is between 0 and PRICE_REMAINING_TO_AVOID
     * I.e.: if the price remaining to pay is 0.5 MXN, the slider will be set to the max points available or the points equivalent to PRICE_REMAINING_TO_AVOID
     * This avoids remaining 0,99 MXN to pay since Adyen rejects payments with less than 1 MXN
     */
    if (remainingToPayTotal < PRICE_REMAINING_TO_AVOID && remainingToPayTotal > 0) {
      // Set slider value depending on the slider direction change
      // If previous value is less than the current value, set the slider to the max points available meaning user is increasing the points
      if (sliderPoints < value) {
        // Set the slider to the max points available
        value = maxPoints;
      } else {
        // Set the slider to the points equivalent to PRICE_REMAINING_TO_AVOID MXN until maxPoints
        value = Math.floor(
          (purchaseTotal + usedBalance - PRICE_REMAINING_TO_AVOID) / profile.pointsFactor,
        );
      }
    }

    setSliderPoints(value === '' ? '' : Number(value));
  };

  const handleOnEdit = () => {
    setDisabled(false);
  };

  const handleOnCancel = () => {
    if (usedPoints) {
      setSliderPoints(usedPoints);
    }
    setDisabled(true);
  };

  // End Slider handlers -------------------

  return {
    minPointsToBeEnable,
    isJustFlatFare,
    totalBalance,
    formattedUsableBalance,
    error,
    canApplyPoints,
    handleOnSubmitPoints,
    handleOnChange,
    handleOnDelete,
    handleOnEdit,
    handleOnCancel,
    maxPoints,
    replacesRegexComas,
    sliderPoints,
    disabled,
  };
};

export default useDotersSlider;
