import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldLabel } from '@reservamos/elements';
import useCieloTDCContext from 'components/cielo/CieloTDCContext/useCieloTDCContext';
import Installments from 'components/purchase/Installments';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import usePurchase from 'hooks/store/usePurchase';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { getExpirationMonths, getExpirationYears } from 'utils/formRenderers';
import CieloTDCFormInput from './CieloTDCFormInput';
import CieloTDCFormSelect from './CieloTDCFormSelect';

/**
 * Renders Cielo TDC form. Relies on CieloTDCProvider to provide the context.
 *
 * @see https://www.notion.so/reservamos/Cielo-TDC-165cd6d27f7680ccb0d3f1ef11b01099?pvs=4
 */
const CieloTDCForm = () => {
  const features = useWhitelabelFeatures();
  const envs = useWhitelabelEnvs();
  const purchase = usePurchase();
  const { accessToken, mountCieloTDCForm, form, isLoading } = useCieloTDCContext();
  const { t } = useTranslation('payment');
  const totalInCentsInteger = Math.round(purchase.total * 100);

  useEffect(() => {
    mountCieloTDCForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className={`${isLoading && 'blur-sm'}`}>
      {/** (Start) Hidden inputs, required values by Cielo but not available for the user */}
      <input type="hidden" name="authEnabled" className="bpmpi_auth" value="true" />
      <input type="hidden" name="accessToken" className="bpmpi_accesstoken" value={accessToken} />
      <input
        type="hidden"
        name="orderNumber"
        className="bpmpi_ordernumber"
        value={purchase.token}
      />
      <input type="hidden" name="currency" className="bpmpi_currency" value={envs.cielo.currency} />
      <input
        type="hidden"
        name="amount"
        className="bpmpi_totalamount"
        value={totalInCentsInteger}
      />
      <input
        type="hidden"
        name="installments"
        className="bpmpi_installments"
        value={String(purchase.monthlySelectedPlan)}
      />
      <input
        type="hidden"
        name="merchantUrl"
        className="bpmpi_merchant_url"
        value={envs.cielo.merchantUrl}
      />
      <input type="hidden" name="paymentMethod" className="bpmpi_paymentmethod" value="credit" />
      {/** (End) Hidden inputs, required values by Cielo but not available for the user */}

      {/** (Start) Dynamic inputs, values that will be provided by the user */}
      <div className="grid gap-16">
        {features.INSTALLMENTS_ENABLED && <Installments />}
        <div className="grid gap-8">
          <div className="flex gap-7 flex-col md:flex-row md:gap-3">
            <CieloTDCFormInput
              id="holderName"
              name="holderName"
              label={t('card_owner_name')}
              value={form.values.holderName}
              disabled={isLoading}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              hasError={Boolean(form.errors.holderName && form.touched.holderName)}
              isValid={Boolean(!form.errors.holderName && form.touched.holderName)}
            />
            <CieloTDCFormInput
              id="cardNumber"
              name="cardNumber"
              label={t('card_number')}
              className="bpmpi_cardnumber"
              value={form.values.cardNumber}
              disabled={isLoading}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              hasError={Boolean(form.errors.cardNumber && form.touched.cardNumber)}
              isValid={Boolean(!form.errors.cardNumber && form.touched.cardNumber)}
            />
          </div>
          <div className="flex gap-7 flex-col md:flex-row md:gap-3">
            <div className="grid gap-1 relative w-full">
              <FieldLabel inputId="expirationDate" text="Expiration Date" />
              <fieldset id="expirationDate" className="flex gap-2">
                <CieloTDCFormSelect
                  id="expirationMonth"
                  name="expirationMonth"
                  label={t('card_month')}
                  className="bpmpi_cardexpirationmonth"
                  options={getExpirationMonths()}
                  value={form.values.expirationMonth}
                  disabled={isLoading}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  hasError={Boolean(form.errors.expirationMonth && form.touched.expirationMonth)}
                  isValid={Boolean(!form.errors.expirationMonth && form.touched.expirationMonth)}
                />
                <CieloTDCFormSelect
                  id="expirationYear"
                  name="expirationYear"
                  label={t('card_year')}
                  className="bpmpi_cardexpirationyear"
                  options={getExpirationYears().map((year) => ({
                    display: year,
                    value: year,
                  }))}
                  value={form.values.expirationYear}
                  disabled={isLoading}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  hasError={Boolean(form.errors.expirationYear && form.touched.expirationYear)}
                  isValid={Boolean(!form.errors.expirationYear && form.touched.expirationYear)}
                />
              </fieldset>
            </div>
            <CieloTDCFormInput
              id="cvv2"
              name="cvv2"
              label={t('card_CVV')}
              value={form.values.cvv2}
              disabled={isLoading}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              hasError={Boolean(form.errors.cvv2 && form.touched.cvv2)}
              isValid={Boolean(!form.errors.cvv2 && form.touched.cvv2)}
            />
          </div>
        </div>
      </div>
      {/** (End) Dynamic inputs, values that will be provided by the user */}
    </form>
  );
};

export default CieloTDCForm;
