// @ts-check
import { FpjsClient, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-spa';
import { trackEvent } from 'user-analytics';
import { FINGERPRINT_REQUESTED } from 'constants/TrackEvents';
import errorMonitoring from '../../errorMonitoring';

let fpClientInstance;

/**
 * UserFingerprint is a function that initializes and retrieves the visitor fingerprint.
 * @returns {Object} - An object with the init and getFingerprint functions.
 */
const UserFingerprint = () => {
  let instanceCacheName = 'reservamos-user_fingerprint_default';
  let instanceCacheTime = 7;
  let instanceTrackingKey = 'reservamos-user_fingerprint_default';
  let instanceEnabled = false;
  let staticUserFingerprint;
  let userMetadata = {
    tag: {},
  };

  /**
   * Notifies an error with the provided error and info.
   * @param {Object} options - The options for notifying the error.
   * @param {Error} options.error - The error to be notified.
   * @param {Object} options.info - Additional information about the error.
   */
  const notifyError = ({ error, info }) => {
    if (instanceEnabled)
      errorMonitoring.notify({
        error,
        info,
      });
  };

  /**
   * Validates the client before executing the provided function.
   * @param {Function} fn - The function to be executed.
   * @returns {Function} - The wrapped function with client validation.
   */
  const withClientValidation =
    (fn) =>
    (...args) => {
      if (!fpClientInstance) {
        notifyError({
          error: new Error('Fingerprint client not initialized'),
          info: { from: 'fingerprint withClientValidation' },
        });
        // eslint-disable-next-line no-unused-vars
        return (...args) => null;
      }
      return fn(...args);
    };

  /**
   * Calculates the expiration date based on the cache time.
   * @returns {number} - The expiration date in milliseconds since the epoch.
   */
  const getExpirationDate = () => {
    // Get the current date and time
    const currentDate = new Date();

    // Add cacheTime days
    const futureDate = new Date(currentDate.getTime() + instanceCacheTime * 24 * 60 * 60 * 1000);

    // Convert the future date to milliseconds since the epoch
    const milliseconds = futureDate.getTime();
    return milliseconds / 1000;
  };

  /**
   * Retrieves the cached fingerprint from local storage.
   * @returns {Object} - The cached fingerprint or null if it is expired or not found.
   */
  const getCachedFingerprint = () => {
    try {
      const fingerprintData = JSON.parse(localStorage.getItem(instanceCacheName));
      return fingerprintData;
    } catch {
      return null;
    }
  };

  /**
   * Sets the cached fingerprint in local storage.
   * @param {object} options - The options for setting the cached fingerprint.
   * @param {object} options.fingerprint - The fingerprint to be cached.
   */
  const setCachedFingerprint = ({ fingerprint }) => {
    const lsValue = {
      body: fingerprint.body,
      expiresAt: getExpirationDate(),
      cacheTime: instanceCacheTime,
    };
    localStorage.setItem(instanceCacheName, JSON.stringify(lsValue));
  };

  /**
   * Sets the user information.
   * @param {Object} options - The options for setting the user information.
   * @param {string} options.linkedId - The linked ID of the user.
   * @param {object} options.tag - Tags to add to the user
   */
  const setUserMetadata = ({ linkedId, tag }) => {
    userMetadata = {
      ...userMetadata,
      ...(linkedId && { linkedId }),
      ...(tag && {
        tag: {
          ...userMetadata.tag,
          ...tag,
        },
      }),
    };
  };

  /**
   * Initializes the fingerprint client.
   * @param {Object} options - The options for initialization.
   * @param {string} options.key - The API key for the fingerprint client.
   * @param {string} options.customEndpoint - The custom URL for the fingerprint client.
   * @param {string} options.customScriptUrl - The custom URL for the fingerprint script.
   * @param {number} options.cacheTime - The time the cache will be saved.
   * @param {string} options.cacheName - The name of the cache key.
   * @param {string} options.trackingKey - The name of the prop/key to be set in the tracking events for fingerprint
   * @param {boolean} options.enabled - Indicates if the fingerprint is enabled
   */
  const init = async ({
    key,
    customEndpoint,
    customScriptUrl,
    cacheName,
    cacheTime,
    trackingKey,
    enabled,
  }) => {
    if (fpClientInstance) return;

    // Setting local variables
    instanceCacheName = cacheName || instanceCacheName;
    instanceCacheTime = cacheTime || instanceCacheTime;
    instanceTrackingKey = trackingKey || instanceTrackingKey;
    instanceEnabled = enabled;

    /**
     * @type {Array<string|object>}
     */
    const customEndpointArray = [FingerprintJSPro.defaultEndpoint];
    /**
     * @type {Array<string|object>}
     */
    const customScriptUrlArray = [FingerprintJSPro.defaultScriptUrlPattern];

    if (customEndpoint) customEndpointArray.unshift(customEndpoint);
    if (customScriptUrl) customScriptUrlArray.unshift(customScriptUrl);

    // TODO: Implement cache management after the trail
    fpClientInstance = new FpjsClient({
      loadOptions: {
        apiKey: key,
        endpoint: customEndpointArray,
        scriptUrlPattern: customScriptUrlArray,
      },
      cache: {
        get: () => {
          return getCachedFingerprint();
        },
        set: (_, value) => {
          setCachedFingerprint({ fingerprint: value });
        },
        remove: () => {
          localStorage.removeItem(instanceCacheName);
        },
        allKeys: () => {
          return [instanceCacheName];
        },
      },
    });

    try {
      const initPromise = fpClientInstance.init();
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Fingerprint initialization timeout')), 750);
      });
      await Promise.race([initPromise, timeoutPromise]);
    } catch (error) {
      notifyError({
        error: new Error(error),
        info: { from: 'fingerprint init' },
      });
      return null;
    }
  };

  /**
   * Gets the visitor fingerprint.
   */
  const setUpFingerprint = withClientValidation(async () => {
    try {
      const { visitorId, cacheHit } = await fpClientInstance.getVisitorData({
        ...userMetadata,
      });
      if (!cacheHit) trackEvent(FINGERPRINT_REQUESTED, { 'User Fingerprint': visitorId });
      staticUserFingerprint = visitorId;
      return visitorId;
    } catch (error) {
      notifyError({
        error: new Error(error),
        info: { from: 'fingerprint getFingerprint' },
      });
      return null;
    }
  });

  /**
   * Returns the cached fingerprint.
   */
  const getFingerprintFromCache = async () => {
    const cachedData = getCachedFingerprint();
    const body = cachedData?.body;
    return body?.visitorId;
  };

  /**
   * Gets the tracking key.
   * @returns {string} The tracking key.
   */
  const getTrackingKey = () => instanceTrackingKey;

  /**
   * Gets the user fingerprint.
   * @returns {string} The user fingerprint.
   */
  const getFingerprint = () => staticUserFingerprint;

  return {
    init,
    setUpFingerprint,
    getFingerprint,
    getCachedFingerprint: getFingerprintFromCache,
    setUserMetadata,
    getTrackingKey,
  };
};

const fingerprintService = UserFingerprint();
export default fingerprintService;
