import 'babel-polyfill';
import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@reservamos/elements';
import App from 'components/App';
import { ErrorBoundary } from '@sentry/react';
import GrowthBookProvider from 'components/GrowthBookProvider';
import { MercadoPagoInstanceProvider } from 'components/mercadoPago';
import OpenPayLoader from 'components/OpenPayLoader';
import CSSVariablesProvider from 'components/CSSVariablesProvider/CSSVariablesProvider';
import defaultEnvs from 'config/defaultEnvs';
import LoyaltyProgramsProvider from './loyalty/context/LoyaltyProgramsProvider';
import CustomI18nextProvider from './CustomI18nextProvider';
import { getInstance } from './utils/taplytics';
import initializeClientServices from './initializers';
import fetchWhiteLabelConfig from './api/fetchWhitelabelConfig';
import '../vendor';
import './styles/theme/_design-system';
import './styles/main';
import './tailwind';
import TranslatedErrorFallback from './errorMonitoring/TranslatedErrorFallback';
import errorMonitoring from './errorMonitoring';

// Initialize Sentry as early as possible
if (defaultEnvs?.sentry) {
  errorMonitoring.init(defaultEnvs.sentry);
}

/**
 * AppLoader component.
 * Initializes the application by loading whitelabel configuration and client services.
 * @returns {React.ReactElement} Application component tree wrapped in providers
 */
const AppLoader = () => {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(null);
  const [store, setStore] = useState(null);

  useEffect(() => {
    /**
     * Initializes application configuration and client services.
     * @returns {Promise<void>}
     */
    const init = async () => {
      try {
        const whitelabelConfig = await fetchWhiteLabelConfig();
        const { storeInstance } = await initializeClientServices(whitelabelConfig);

        if (whitelabelConfig.env.taplytics?.enabled) {
          const taplytics = getInstance();
          taplytics.propertiesLoaded(() => {
            setConfig(whitelabelConfig);
            setStore(storeInstance);
          });
        } else {
          setConfig(whitelabelConfig);
          setStore(storeInstance);
        }
      } catch (err) {
        // Force an error that React can render
        setError(() => {
          throw err;
        });
      }
    };

    init();
  }, []);

  if (!config || !store) return null;

  return (
    <Provider store={store}>
      <GrowthBookProvider>
        <CustomI18nextProvider>
          <BrowserRouter>
            <OpenPayLoader
              config={{
                ...config.env.openPay,
                openPayRegion: config.features.OPENPAY_REGION,
              }}
            >
              <MercadoPagoInstanceProvider config={config.env.mercadoPago}>
                <CSSVariablesProvider
                  theme={config.env.theme}
                  funnelStyle={config.features.FUNNEL_STYLE}
                  funnelStyleBrandVariation={config.env.brand.toUpperCase()}
                >
                  <ThemeProvider theme={config.env.theme}>
                    <LoyaltyProgramsProvider>
                      <App />
                    </LoyaltyProgramsProvider>
                  </ThemeProvider>
                </CSSVariablesProvider>
              </MercadoPagoInstanceProvider>
            </OpenPayLoader>
          </BrowserRouter>
        </CustomI18nextProvider>
      </GrowthBookProvider>
    </Provider>
  );
};

render(
  <ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag('fallbackScreen', true);
    }}
    fallback={({ resetError }) => <TranslatedErrorFallback resetError={resetError} />}
  >
    <AppLoader />
  </ErrorBoundary>,
  document.getElementById('app'),
);
