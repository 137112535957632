import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CurrentLang } from 'utils/lang';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { loadSearchWidgetScript } from 'utils/searchWidget';
import useGrowthBookFeatureValue from 'components/GrowthBookProvider/useGrowthBookFeatureValue';

/**
 * Search widget component. Implements the search widget from reservamos.mx
 * @param {Object} props - Component props
 * @param {string} props.origin - Origin city slug
 * @param {string} props.destination - Destination city slug
 * @param {string} props.departureDate - Departure date
 * @param {string} props.returnDate - Return date
 * @param {string} props.hybridTrip - Hybrid trip. Could be departure, return or both
 * @param {function} props.searchCallback - Callback to be called when the search button is clicked
 * @returns {JSX} - React component
 */
const SearchWidget = ({
  origin,
  destination,
  departureDate,
  returnDate,
  hybridTrip,
  searchCallback,
}) => {
  const { search, whitelabelConfig } = useSelector((state) => state);
  const { env } = whitelabelConfig;
  const searchPassengersAB = useGrowthBookFeatureValue('search_passengers');
  const history = useHistory();
  const { t } = useTranslation('general');
  const departure = search?.get('departure');
  const returns = search?.get('return');
  const passengers = search?.get('passengers')?.toJS();
  const widgetSelector = useRef(uuidv4());
  const widgetRef = useRef(null);

  const widgetProps = env.searchWidget;
  const lang = CurrentLang();

  let decodeSearchPassengers = '';
  Object.keys(passengers).forEach((key) => {
    if (passengers[key] && widgetProps.passengersOptions?.includes(key)) {
      decodeSearchPassengers += `${key}=${passengers[key]},`;
    }
  });
  const withInitialData = [origin, destination, departureDate, returnDate, hybridTrip].some(
    (value) => value !== undefined,
  );

  // Initial data to be used in the search widget
  const initialData = {
    'data-prop-origin': origin,
    'data-prop-destination': destination,
    'data-prop-departure-date': departure?.get('isOpen') ? t('open_ticket') : departureDate,
    'data-prop-hybrid-trip': hybridTrip,
  };

  if (returnDate) {
    initialData['data-prop-return-date'] = returnDate;
  }

  if (returns?.get('isOpen')) {
    initialData['data-prop-return-date'] = t('open_ticket');
  }

  // Creates the search widget with the current selector
  const createSearchWidget = () => {
    widgetRef.current = window.createSearchWidget({
      selector: `[data-widget-host="${widgetSelector.current}"]`,
      clean: true,
    });
  };

  useEffect(() => {
    let script;
    const { useDynamicLoad } = env.searchWidget;
    if (useDynamicLoad) {
      // If the search widget is already loaded, create it
      if (window.createSearchWidget) {
        createSearchWidget();
      } else {
        // If the search widget is not loaded, wait for it to be loaded and create it
        window.addEventListener('searchWidgetReady', createSearchWidget);
      }
    } else {
      script = loadSearchWidgetScript(env.searchWidget.version);
    }

    return () => {
      if (useDynamicLoad) {
        // Destroy the search widget when the component is unmounted
        widgetRef.current?.destroy();
        // Remove the listener for the search widget
        window.removeEventListener('searchWidgetReady', createSearchWidget);
      } else {
        document.body.removeChild(script);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configFeaturesFlags = () => {
    const { featuresFlags } = widgetProps;
    if (!featuresFlags) return widgetProps;
    const newWidgetProps = { ...widgetProps };
    if (featuresFlags.showDepartureDatePickerText)
      newWidgetProps.departureDatePickerText = t('text.departureCalendarText');
    if (featuresFlags.showReturnDatePickerText)
      newWidgetProps.returnDatePickerText = t('text.returnCalendarText');
    return newWidgetProps;
  };
  /**
   * Transform props to be used in the search widget
   * Props are data-prop-* attributes in the div element
   * Values from config.searchWidget are camelCase and should be converted to kebab-case
   * @returns {object} - Transformed props
   */
  const transformProps = () => {
    const widgetWithExtras = configFeaturesFlags();
    const transformedProps = {};
    Object.keys(widgetWithExtras).forEach((key) => {
      const value = widgetWithExtras[key];
      const transformedKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
      transformedProps[`data-prop-${transformedKey}`] = value;
    });
    return transformedProps;
  };

  /**
   * Listen to search event from the search widget and redirect to the search page
   * This hook sets the listener and removes it when the component is unmounted
   */
  useEffect(() => {
    const searchEventListener = (event) => {
      const { absoluteSearchUrl } = event.detail;
      const { pathname, search } = new URL(absoluteSearchUrl);
      history.push(pathname + search);
      if (searchCallback) searchCallback();
    };
    // The event is removed and added again to avoid multiple listeners
    window.removeEventListener('SearchWidget:search', searchEventListener);
    window.addEventListener('SearchWidget:search', searchEventListener);

    return () => {
      window.removeEventListener('SearchWidget:search', searchEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { useDynamicLoad } = env.searchWidget;

  return (
    <div
      className="searchbar"
      data-widget-host={useDynamicLoad ? widgetSelector.current : 'habitat'}
      data-prop-lang={lang}
      data-prop-compact-height=""
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...transformProps()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(withInitialData && initialData)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      data-prop-trigger-search-event
      data-prop-geolocation-origin="false"
      data-prop-passengers-values={decodeSearchPassengers}
      data-prop-passengers-dropdown={Boolean(searchPassengersAB)}
    />
  );
};

SearchWidget.propTypes = {
  origin: PropTypes.string,
  destination: PropTypes.string,
  departureDate: PropTypes.string,
  returnDate: PropTypes.string,
  hybridTrip: PropTypes.string,
  searchCallback: PropTypes.func,
};

export default SearchWidget;
