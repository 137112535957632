import { useContext } from 'react';
import CieloTDCContext from './CieloTDCContext';

const useCieloTDCContext = () => {
  const context = useContext(CieloTDCContext);

  if (!context) {
    throw new Error('useCieloTDC must be used within a CieloTDCProvider');
  }

  return context;
};

export default useCieloTDCContext;
